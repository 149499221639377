import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import GravityForm from '../gravity-forms/gravity-forms';
import { IJoinUsProps } from './join-us-interface';
import './join-us.scss';

export default class JoinUs extends BaseComponent<IJoinUsProps> {
  public render(): ReactNode {
    const { form } = this.props;
    return (
      <div className="c-joinus">
        <div className="row c-joinus__wrapper">
          <div className="col-lg-6 c-joinus__col">
            <h3 className="c-joinus__title">Join us</h3>
            <p className="c-joinus__text">
              Stay up to date on our movement, and learn how you can get
              involved and make a difference.
            </p>
          </div>
          <div className="col-lg-6 c-joinus__col">
            <GravityForm form={form} formClassName="c-form-newsletter" />
          </div>
        </div>
      </div>
    );
  }
}
