import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import MediaPostItem from '../media-post-item/media-post-item';
import SolidButton from '../solid-button/solid-button';
import {
  IMediaItemGridProperties,
  IMediaItemGridState,
} from './media-item-grid-interface';

export default class MediaItemGrid extends BaseComponent<
  IMediaItemGridProperties,
  IMediaItemGridState
> {
  public constructor(props: IMediaItemGridProperties) {
    super(props);

    this.state = {
      latestIndex:
        props.showMoreCount > props.items.length
          ? props.items.length
          : props.showMoreCount,
    };
  }

  public render(): ReactNode {
    const { items, showMoreCount } = this.props;
    const { latestIndex } = this.state;
    let showMoreItems = [];

    if (latestIndex >= 0) {
      showMoreItems = items.slice(0, latestIndex);
    }
    return (
      <div className='c-media-posts'>
        {showMoreItems.length > 0 && (
          <div className="row c-posts-all">
            {showMoreItems.map((mediaItem, index) => {
              return <MediaPostItem item={mediaItem} key={index} />;
            })}
          </div>
        )}

        {latestIndex < items.length - 1 && (
          <SolidButton
            onClick={(): void => {
              this.setState((prevState) => {
                return {
                  latestIndex:
                    prevState.latestIndex + showMoreCount > items.length
                      ? items.length
                      : prevState.latestIndex + showMoreCount,
                };
              });
            }}
          >
            Show More
          </SolidButton>
        )}
      </div>
    );
  }
}
