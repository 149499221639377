import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { SolidLinkColorSchemas } from './solid-link-enum';
import { ISolidLinkProperties } from './solid-link-interface';
import './solid-link.scss';

export default class SolidLink extends BaseComponent<ISolidLinkProperties> {
  public render(): ReactNode {
    const { colorSchema = SolidLinkColorSchemas.PURPLE, to } = this.props;
    const schemaClass: string = `is-${colorSchema}`;
    const classNames: string = ['c-btn', schemaClass].join(' ');

    return (
      <Link className={classNames} to={to}>
        {this.props.children}
      </Link>
    );
  }
}
