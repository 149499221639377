import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import SolidLink from '../solid-link/solid-link';
import { SolidLinkColorSchemas } from '../solid-link/solid-link-enum';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import {
  IMediaPostItemProperties,
  IMediaPostItemStates,
} from './media-post-item-interface';

export default class MediaPostItem extends BaseComponent<
  IMediaPostItemProperties,
  IMediaPostItemStates
> {
  public constructor(props: IMediaPostItemProperties) {
    super(props);
    this.state = {
      imageIsReady: false,
    };
  }

  componentDidMount(): void {
    const img = new Image();
    img.onload = () => this.setState({ imageIsReady: true });
    img.src = this.props.item.featuredImage?.node.sourceUrl;
  }
  public render(): ReactNode {
    const { item } = this.props;
    const { imageIsReady } = this.state;

    return (
      <div className="col-lg-3 col-md-6 card c-card_items">
        <div className="c-card__container">
          <div className="media media--transparent media--card media--hover-effect c-card__img">
            {imageIsReady ? (
              <img
                srcSet={item.featuredImage?.node.srcSet}
                sizes="(min-width: 1504px) 702px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                src={item.featuredImage?.node.sourceUrl}
                alt={item.featuredImage?.node.altText}
                loading="lazy"
                width={item.featuredImage?.node.mediaDetails.width}
                height={item.featuredImage?.node.mediaDetails.height}
              />
            ) : (
              <div className="loading-overlay__spinner">
                <svg aria-hidden="true" focusable="false" role="presentation" className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle className="path" fill="none" strokeWidth="6" cx="33" cy="33" r="30"></circle>
                </svg>
              </div>
            )}
            <Link
              className="c-link"
              to={`/media${item.categories?.nodes[0].uri}${item.slug}`}
            ></Link>
            <SolidLink
              to={`/media${item.categories?.nodes[0].uri}`}
              colorSchema={SolidLinkColorSchemas.HIGHLIGHTED}
            >
              {item.categories?.nodes[0].name}
            </SolidLink>
          </div>
          <div className="c-media-featured__wrapper">
            <div className="c-card__details">
              <span>{new Date(item.date).toDateString()}</span>
              <span>BY {item.author?.node.name}</span>
            </div>
            <h6 className="c-card__title">
              <Link
                className="c-link"
                to={`/media${item.categories?.nodes[0].uri}${item.slug}`}
              >
                {item.title}
              </Link>
            </h6>
            <SolidLink
              to={`/media${item.categories?.nodes[0].uri}${item.slug}`}
              colorSchema={SolidLinkColorSchemas.HIGHLIGHTED}
            >
              Read More
              <SvgIcon
                width="13"
                viewBox="0 0 20 14"
                paths={[
                  { command: SvgIconPathCommand.ARROW_RIGHT, fill: '#4A1D96' },
                ]}
              />
            </SolidLink>
          </div>
        </div>
      </div>
    );
  }
}
