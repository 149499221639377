import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import BreadCrumb from '../../components/bread-crumb/bread-crumb';
import ContainerBox from '../../components/container-box/container-box';
import JoinUs from '../../components/join-us/join-us';
import MediaItemGrid from '../../components/media-item-grid/media-item-grid';
import Share from '../../components/share/share';
import SocialMetadata from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import WordPressPageLayout from '../../components/wordpress-page-layout/wordpress-page-layout';
import { IMediaPostTemplateProperties } from './media-post-interface';

export default class MediaPostTemplate extends BaseComponent<IMediaPostTemplateProperties> {
  public render(): ReactNode {
    const { [0]: form } = this.props.data.form.nodes;
    const { post, relatedPosts } = this.props.data;
    return (
      <WordPressPageLayout title={post.title}>
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={post.title}
          description={post.title}
          imageResourceFileName={post.featuredImage?.node.sourceUrl}
        />
        <ContainerBox className="c-section--pageheader">
          <div className="c-pageheader">
            <BreadCrumb
              crumbItems={[
                {
                  label: 'State of Democracy',
                  location: '/',
                },
                {
                  label: 'Media',
                  location: '/media',
                },
                {
                  label: post.categories?.nodes[0].name,
                  location: `/media${post.categories?.nodes[0].uri}`,
                },
              ]}
            />
            <div className="c-section">
              <h1 className="c-section__title">{post.title}</h1>
            </div>
          </div>
        </ContainerBox>
        <ContainerBox className='c-section--article u-media-width'>
          <article itemScope itemType="http://schema.org/Article">
            <div className='c-article__hero'>
              <div className="media media--transparent media--landscape">
                <img srcSet={post.featuredImage?.node.srcSet}
                    sizes="(min-width: 1504px) 702px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                    src={post.featuredImage?.node.sourceUrl}
                    alt={post.featuredImage?.node.altText}
                    loading="lazy"
                    width={post.featuredImage?.node.mediaDetails.width}
                    height={post.featuredImage?.node.mediaDetails.height} />
              </div>
            </div>
            <div className="c-article__wrapper">
              <div className="c-article__col">
                <div className="c-article__share">
                  <div className="c-article-share__title">Share</div>
                  <Share
                    description={post.title}
                    absoluteLink={`/media${post.categories?.nodes[0].uri}${post.slug}`}
                    imageResourceFileName=""
                    isExpanded={true}
                  />
                </div>
              </div>
              <div className="c-article__col">
                <div className='c-article__author'>
                  <img
                    alt={post.author?.node.name}
                    src={post.author?.node.avatar.url}
                    width={post.author?.node.avatar.width}
                    height={post.author?.node.avatar.height}
                    loading='lazy'
                    className='c-article__author-image'
                  />
                  <span>{`BY ${post.author?.node.name}`}</span>
                  <span>{new Date(post.date).toDateString()}</span>
                </div>
                <div className='c-article__content s-content'
                  dangerouslySetInnerHTML={{ __html: post.content }}
                ></div>
              </div>
            </div>
              
          </article>
        </ContainerBox>
        <ContainerBox className='c-section--joinus u-media-width'>
          <JoinUs form={form} />
        </ContainerBox>
        <ContainerBox className='c-section--related u-media-width'>
        <div className="c-section">
          <h2 className="c-section__title">Related Articles</h2>
        </div>
        <MediaItemGrid items={relatedPosts?.nodes} showMoreCount={4} />
        </ContainerBox>
      </WordPressPageLayout>
    );
  }
}

export const pageQuery = graphql`
  query MediaPost($postId: String, $categoryId: String) {
    form: allGfForm(filter: { slug: { eq: "join-us" } }) {
      nodes {
        apiURL
        formFields {
          isRequired
          label
          type
          placeholder
          id
          cssClass
        }
        title
        button {
          text
        }
      }
    }
    post: wpPost(
      id: { eq: $postId }
      categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
    ) {
      id
      title
      slug
      uri
      date
      content
      author {
        node {
          name
          avatar {
            url
            width
            height
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
          altText
          srcSet
          sizes
          mediaDetails {
            height
            width
          }
        }
      }
      categories {
        nodes {
          slug
          uri
          name
        }
      }
    }
    relatedPosts: allWpPost(
      sort: { order: DESC, fields: date }
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
      }
      limit: 4
    ) {
      nodes {
        uri
        title
        slug
        id
        featuredImage {
          node {
            sourceUrl
            altText
            srcSet
            sizes
            mediaDetails {
              height
              width
            }
          }
        }
        author {
          node {
            name
          }
        }
        date
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;
