import { Link } from "gatsby";
import React, { ReactNode } from "react";
import BaseComponent from "../../base/base-component";
import SvgIcon from "../svg-icon/svg-icon";
import { SvgIconPathCommand } from "../svg-icon/svg-icon-enum";
import { IBreadCrumbProperties, ICrumbItem } from "./bread-crumb-interface";
import "./bread-crumb.scss";

export default class BreadCrumb extends BaseComponent<IBreadCrumbProperties> {
  public render(): ReactNode {
    const { crumbItems } = this.props;

    return (
      <ul className="c-breadcrumb">
        {crumbItems.map((crumb: ICrumbItem, index: number) => {
          return (
            <li key={index}>
              <Link to={crumb.location}> {crumb.label} </Link>
              <SvgIcon
                width="6"
                height="10"
                viewBox="0 0 6 10"
                paths={[
                  {
                    command: SvgIconPathCommand.CHEVRON_RIGHT,
                    fill: "#0D0C0D",
                  },
                ]}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
